<template>
  <div>
    <div class="flex justify-between items-center">
      <h3 v-if="title">
        {{title}}
      </h3>
      <div v-if="enableSelectActions" class="flex items-center overflow-auto">
        <div>
          <void-button class="ml-2"
                       :disabled="selectedNonVoidCount.length === 0"
                       @click="showVoidDialog = true"
          >
            {{ $t('Void') }}
          </void-button>
        </div>
        <div v-if="type !== PaymentFor.Billing">
          <base-button :disabled="selectedPayments.length === 0"
                       variant="white"
                       class="ml-2"
                       @click="showPositiveVerificationDialog = true"
          >
            <EyeIcon class="w-5 h-5 mr-2"/>
            <span>{{ $t('Positive Verification') }}</span>
          </base-button>
        </div>
        <div class="ml-2">
          <DownloadAPChecksDialog
            v-if="!isARModule"
            :disabled="selectedPayments.length === 0"
            :payments="selectedPayments"
          />
        </div>
        <PreviewChecksButton
          v-if="!isARModule"
          :disabled="selectedPayments.length === 0"
          :download-function="downloadChecks"
          class="ml-2"
        >
          <PrinterIcon class="w-5 h-5 mr-2"></PrinterIcon>
          <span>{{ $t('Print Checks (Old)') }}</span>
        </PreviewChecksButton>
      </div>
    </div>
    <BaseDataTable
        :columns="columns"
        :url="url"
        :url-params="allUrlParams"
        :default-filters="hasFilters"
        :is-expandable="true"
        :has-summary="true"
        actions="search,refresh"
        @data-fetch="onDataFetch"
        @meta-fetch="onMetaFetch"
        ref="table"
    >
      <template #select="{row}">
        <base-checkbox
            v-model="row.selected"
            :disabled="!isCheckDownloadable(row)"
        />
      </template>
      <template #vendors.code="{row}">
        <VendorLink :id="get(row, 'attributes.vendor_id')" :show-description="false"/>
      </template>
      <template #customers.code="{row}">
        <CustomerLink :id="get(row, 'attributes.customer_id')" :show-description="false"/>
      </template>
      <template #attributes.bank_id="{row}">
        <BankLink :id="row.attributes.bank_id" :display-only-initials="true"/>
      </template>
      <template #attributes.number_summary>
        <span class="font-medium ml-2">{{$t('Totals')}}</span>
      </template>
      <template #attributes.net_amount_summary>
        <span class="font-medium">{{$formatPrice(paymentsMeta.total_net_amount)}}</span>
      </template>
      <template #attributes.discount_amount_summary>
        <span class="font-medium">{{$formatPrice(paymentsMeta.total_discount_amount)}}</span>
      </template>
      <template #attributes.telemetry.send="{row}">
        <base-tooltip :content="$t(`No. of times the payment was sent via email`)">
          <status-badge :status="get(row, 'attributes.telemetry.send', 0)"></status-badge>
        </base-tooltip>
      </template>
      <template #attributes.telemetry.download="{row}">
        <base-tooltip :content="$t(`No. of times the payment was downloaded from the sent emails`)">
          <status-badge :status="get(row, 'attributes.telemetry.download', 0)"></status-badge>
        </base-tooltip>
      </template>
      <template #expand-content="{row}">
        <PaymentInvoices
            v-if="type === PaymentFor.Invoice"
            :invoices="get(row, 'originalRow.relationships.invoices', [])"
            class="px-4 pb-4"
        />
        <PaymentBillings
            v-else-if="type === PaymentFor.Billing"
            :billings="get(row, 'originalRow.relationships.billings', [])"
            class="px-4 pb-4"
        />
      </template>
    </BaseDataTable>
    <VoidPaymentsDialog
      v-if="showVoidDialog"
      :open.sync="showVoidDialog"
      :payments="selectedPayments.map(b => b.id)"
      :forBillings="forBillings"
      @cancel="showVoidDialog = false">
    </VoidPaymentsDialog>
    <PositiveVerificationDialog v-if="showPositiveVerificationDialog"
                                :open.sync="showPositiveVerificationDialog"
                                :action="positivePayAction"
                                :payload="positivePayPayload"
    />
  </div>
</template>
<script>
  import { EyeIcon, PrinterIcon } from 'vue-feather-icons'
  import VoidPaymentsDialog from "@/modules/accounts-payable/components/checks/VoidPaymentsDialog";
  import PositiveVerificationDialog from '@/modules/accounts-payable/components/payment/PositiveVerificationDialog'
  import PaymentInvoices from "@/modules/accounts-payable/components/payment/PaymentInvoices";
  import { paymentTypes } from "@/modules/accounts-payable/components/invoice/paymentUtils";
  import PaymentBillings from "@/modules/accounts-receivable/components/payments/PaymentBillings.vue";
  import DownloadAPChecksDialog from "@/modules/accounts-payable/components/payment/DownloadAPChecksDialog.vue";
  import axios from "axios";
  import PreviewChecksButton from "@/components/common/PreviewChecksButton.vue";

  export default {
    components: {
      PreviewChecksButton,
      DownloadAPChecksDialog,
      EyeIcon,
      PrinterIcon,
      PositiveVerificationDialog,
      PaymentInvoices,
      PaymentBillings,
      VoidPaymentsDialog,
    },
    props: {
      status: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'invoice',
      },
      urlParams: {
        type: Object,
        default: ({}),
      },
      hasFilters: {
        type: Boolean,
        default: true
      },
      canVoid: {
        type: Boolean,
        default: false
      },
      enableSelectActions: {
        type: Boolean,
        default: false
      },
      forBillings: {
        type: Boolean,
      }
    },
    data() {
      return {
        payments: [],
        showPositiveVerificationDialog: false,
        showVoidDialog: false,
        paymentsMeta: {
          total_net_amount: 0,
          total_discount_amount: 0,
        },
        PaymentFor: {
          Invoice: 'invoice',
          Billing: 'billing',
        }
      }
    },
    computed: {
      isARModule() {
        return this.$route.path.includes('accounts-receivable')
      },
      url() {
        return `/restify/${this.type}-payments`
      },
      allUrlParams() {
        let params = {
          related: 'invoices,journal'
        }

        if (this.type === this.PaymentFor.Billing) {
          params.related = 'billings,journal'
        }

        if (this.status) {
          params.status = this.status
        }

        return {
          ...params,
          ...this.urlParams,
        }
      },
      positivePayAction() {
        return `/restify/${this.type}-payments/action?action=generate-positive-pay`
      },
      positivePayPayload() {
        if (!this.selectedPayments?.length) {
          return {
            repositories: ['all'],
          }
        }
        return {
          repositories: this.selectedPayments.map(b => b.id)
        }
      },
      selectedPayments() {
        return this.payments.filter(b => b.selected)
      },
      selectedNonVoidCount() {
        return this.payments.filter(b => b.selected && b?.attributes?.status !== 'voided')
      },
      columns() {
        const isBilling = this.type === this.PaymentFor.Billing
        const columns = [
          {
            label: this.$t('Number'),
            prop: 'attributes.number',
            maxWidth: 90,
          },
          {
            label: isBilling ? this.$t('Customer') : this.$t('Vendor'),
            prop: isBilling ? 'customers.code' : 'vendors.code',
            maxWidth: 90,
          },
          {
            label: this.$t('Type'),
            prop: 'attributes.type',
            maxWidth: 120,
            component: 'Status'
          },
          {
            label: this.$t('Status'),
            prop: 'attributes.status',
            maxWidth: 90,
            component: 'StatusLink'
          },
          {
            label: this.$t('Bank'),
            prop: 'attributes.bank_id',
            minWidth: 70,
            maxWidth: 100,
          },
          {
            label: this.$t('Created At'),
            prop: 'attributes.created_at',
            minWidth: 100,
            maxWidth: 160,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Date'),
            prop: 'attributes.date',
            minWidth: 100,
            maxWidth: 160,
            component: 'FormattedDate',
          },
          {
            label: this.$t('Discount'),
            prop: 'attributes.discount_amount',
            component: 'FormattedPrice',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
          },
          {
            label: this.$t('Cash on deposit'),
            prop: 'attributes.from_cash_on_deposit_amount',
            component: 'FormattedPrice',
            minWidth: 100,
            maxWidth: 150,
            align: 'right',
            renderIf: () => isBilling,
          },
          {
            label: this.$t('Amount'),
            prop: 'attributes.net_amount',
            component: 'FormattedPrice',
            minWidth: 120,
            maxWidth: 180,
            align: 'right',
          },
          {
            label: this.$t('Sent Count'),
            prop: 'attributes.telemetry.send',
            maxWidth: 80,
            align: 'center',
            component: 'Count',
          },
          {
            label: this.$t('Download Count'),
            prop: 'attributes.telemetry.download',
            maxWidth: 80,
            align: 'center',
            component: 'Count',
          },
        ]
        if (this.enableSelectActions) {
          columns.unshift({
            label: '',
            prop: 'select',
            minWidth: 40,
            maxWidth: 40,
            smallCell: true,
          })
        }
        return columns.filter(column => column.renderIf ? column.renderIf() : true)
      },
    },
    methods: {
      async downloadChecks(model = {}) {
        const url = `/restify/${this.type}-payments/action?action=print-${this.type}-payment-checks`
        const repositories = this.selectedPayments.map(b => b.id)
        const data = {
          repositories,
          ...model,
        }
        const params = { responseType: 'blob' }

        return await axios.post(url, data, params)
      },
      onMetaFetch(meta) {
        this.paymentsMeta = meta
        this.$emit('payments-meta', meta)
      },
      onDataFetch(data) {
        this.payments = data
        this.$emit('data-fetch', data)
      },
      isCheckDownloadable(payment) {
        return [paymentTypes.Check, paymentTypes.JointPayment, paymentTypes.DirectDeposit].includes(payment.attributes.type)
      },
      refresh() {
        this.$refs?.table?.refresh()
      }
    },
    watch: {
      showVoidDialog(value, oldValue) {
        if (!value, oldValue) {
          this.refresh()
          this.$emit('refresh')
        }
      }
    }
  }
</script>
